@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

#Topmargin1 {
  margin-top: 138px;
  font-weight: 200;
  font-style: normal;
}

#Topmargin {
  margin-top: 150px;
}

.header-top a {
  font-size: small;
  text-decoration: none;
  padding-right: 2px;
}

.header-top i {
  padding-left: 5px;
  color: skyblue;
}

.wrapper {
  background-color: transparent;
  box-shadow: rgba(50, 50, 93, 0.3) 0px 30px 30px 10px;
}

.wrapper:hover {
  background-color: #fff;
}

.wrapper1 {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.3) 0px 30px 30px 10px;
}

/* carousel mobile style... */



nav {

  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;


}

nav .wrapper {
  position: relative;


  max-width: 1300px;
  padding: 0px 30px;
  height: 100px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .logo a {
  color: black;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}

.wrapper .nav-links {
  display: inline-flex;
}

nav .wrapper {
  position: relative;
  max-width: 1300px;
  padding: 0px 30px;
  height: 100px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper1 .logo a {
  color: black;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}

.wrapper1 .nav-links {
  display: inline-flex;
}

.nav-links li {
  list-style: none;
}

.nav-links li a {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;

}

.nav-links li a:hover {
  background: skyblue
}

.nav-links .mobile-item {
  display: none;
}

.nav-links .drop-menu {
  position: absolute;
  background: skyblue;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 100px;
  opacity: 1;
  visibility: visible;
}

.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}

.mega-box .content {
  background: skyblue;
  margin-top: -20px;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 45px;
}

.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content .row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}

.content .row .mega-links {

  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.row .mega-links li {
  padding: 0 20px;
}

.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
  display: block;
}

.row .mega-links li a:hover {
  color: #f2f2f2;
}

.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

.wrapper1 .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.wrapper1 .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 970px) {
  .wrapper .btn {
    display: block;
  }

  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: skyblue;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }

  .wrapper1 .btn {
    display: block;
  }

  .wrapper1 .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: skyblue;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }

  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #242526;
  }

  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }

  #menu-btn:checked~.nav-links {
    left: 0%;
  }

  #menu-btn:checked~.btn.menu-btn {
    display: none;
  }

  #close-btn:checked~.btn.menu-btn {
    display: block;
  }

  .nav-links li {
    margin: 15px 10px;
  }

  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }

  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }

  #showDrop:checked~.drop-menu,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }

  #showDroped:checked~.drop-menu,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }

  #showDrops:checked~.drop-menu,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }

  #showDropelink:checked~.drop-menu,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }

  .nav-links .desktop-item {
    display: none;
  }

  .nav-links .desktop-itemed {
    display: none;
  }

  .nav-links .desktop-items {
    display: none;
  }

  .nav-links .mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .nav-links .mobile-item:hover {
    background: #3A3B3C;
  }

  .drop-menu li {
    margin: 0;
  }

  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }

  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }

  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }

  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }

  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }

  .row .mega-links li {
    margin: 0;
  }

  .content .row header {
    font-size: 19px;
  }
}

nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}

.body-text div {
  font-size: 45px;
  font-weight: 600;
}






.border-top-theme-color-3px {
  border-top: 3px solid skyblue !important;
}

.p-0 {
  padding: 0 !important;
}

@media only screen and (min-width: 950px) {
  .header-top .row {
    height: 30px;
    margin-top: 3px;

  }
}

@media only screen and (max-width: 450px) {
  .header-top .col-sm-4 {
    padding-left: 70px;
    margin-bottom: 0px;



  }

  .header-top .col-sm-3 {
    margin-top: -12px;
    margin-bottom: -5px;



  }
}


/* .NavStyles img{
  margin-top: -10px;
  padding-top: -10px;
 }

.NavStyles .ms-auto .navlink{
  color: black;
  margin-left: 15px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.NavStyles .ms-auto .navlink:hover{
  background-color: rgb(245, 41, 41);
  border-radius: 999rem;
  padding: 0.8rem;
  width: auto;
  height: auto;
  color: white;
  -webkit-transition: all 0.55s ;
  transition: all 0.55s;
  padding-top: 13px;
  color: whitesmoke;
}
.NavStyles .ms-auto #navbarScrollingDropdown {
  color: black;
 
  margin-left: 15px;
  height: 52px;
  transition: 0.3s;
}
.NavStyles .ms-auto #navbarScrollingDropdown:hover{
  background-color: rgb(245, 41, 41);
  border-radius: 999rem;
  padding: 0.8rem;
  -webkit-transition: all 0.55s ;
  transition: all 0.55s;
  padding-top: 13px;
  color: whitesmoke;
  
}

.NavStyles  .DropItems{
 transition: 0.3s;
  
}
.NavStyles  .DropItems:hover{
  background-color: rgb(177, 174, 174);
   
 }
@media screen and (max-width:500px) {
  .NavStyles  .DropItems:hover{
    background-color: rgb(177, 174, 174);
     
   }
  
}


@media screen and (max-width:500px) {
  .NavStyles #navbarScroll .navlink{
   border-radius: 10px;
   margin-left: 0px;
  }
  
}

@media screen and (max-width:500px) {
  .NavStyles #navbarScroll #navbarScrollingDropdown{
   margin-left: 0px;
  }
  
}



@media screen and (max-width:450px) {
  .NavStyles .ms-auto .navlink:hover{

  
  padding-left: 10px 

  }
  
}
@media screen and (max-width:400px) {
  .NavStyles .ms-auto .navlink:hover{

  
  padding-left: 10px 

  }
  
}
@media screen and (max-width:450px) {
  .NavStyles .ms-auto #navbarScrollingDropdown:hover{
   
  padding-left: 10px 

  }
  
}


 */



.iconss {
  text-align: right;

  background-color: #ff7800
}


.iconss a {
  text-align: end;
  margin: 20px;
  transition: 0.3s;
  color: snow;
}

.iconss a:hover {
  color: #242526;
}

.iconss .dropdown {
  position: relative;
  display: inline-block;
}

.iconss .dropbtn {
  background-color: #ff7800;
  color: white;
  /* padding: 16px;
  font-size: 16px; */
  border: none;
}

.iconss .dropdown-content {
  display: none;
  position: absolute;

  margin-left: -40px;
  border-radius: 10px;
  background-color: #f1f1f1;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.wrapper1 .mobilelogo {
  display: none;
}

.wrapper1 .mobileLinks {
  display: none;
}

.wrapper1 .mobileLinks1 {
  display: none;
}

.wrapper1 .mobileInsurance {
  display: none;
}

.wrapper1 .insurance {
  display: none;
}
.iconss .emergency1{
  color: red;
  padding: 0.6%;
  background-color: white;
  
  margin-top: 1%;
  text-decoration: none;
}

.iconss .emergency2{
  color: rgb(0, 0, 96);
  padding: 0.6%;
  background-color: white;
  
  margin-top: 1%;
 
  text-decoration: none;
}
@media (max-width:499px) {
  .iconss .emergency1 {
    display: none;
  }

  .iconss .emergency2 {
    display: none;
  }
  .iconss .emergency {
    display: none;
  }

  .wrapper1 .logo {
    display: none;
  }

  .wrapper1 .mobilelogo {
    display: block;
    width: 25%;
    margin-left: -25px;
  }

  nav .wrapper1 {
    background-color: #ff7800;
  }

  .wrapper1 .mobileLinks {
    display: inline;
    margin-top: -12%;
    margin-left: 10px;
    margin-bottom: 5px;

  }

  .wrapper1 .mobileLinks1 {
    margin-left: -28%;
    margin-top: 10%;
    display: block;
  }

  #Topmargin {
    margin-top: 140px;
  }

  #Topmargin1 {
    margin-top: 108px;
  }

  .wrapper1 .mobileLinks1 .em2 {
    background-color: white;
    color: rgb(0, 0, 96);
    text-decoration: none;
    padding: 5%;
    border-radius: 5px;
    font-size: 14px;
  }

  .wrapper1 .mobileLinks .em1 {
    background-color: white;
    color: red;
    text-decoration: none;
    padding: 5%;
    border-radius: 5px;
    font-size: 16px;

  }

  .wrapper1 .mobileLinks .em2 {
    background-color: white;
    color: red;
    text-decoration: none;
    padding: 10%;
    padding-bottom: -25%;
  }

  .wrapper1 .mobileInsurance {
    display: block;
    margin-top: 12%;
    
  }

  .wrapper1 .mobileInsurance>img {
    width: 15%;
    margin-left: 12.5%;
    margin-top: -15%;
    
    position: fixed;
  }

  .wrapper1 .insurance {
    display: block;
    margin-left: 29px;
   text-align: center;
    font-size: 10px;
    
    line-height: 125%;
    text-decoration: none;
    color: #fff;

  }

  .wrapper1 #menu-btn {
    background-color: #242526;
  }

 
}
@media (max-width:430px) {
  .iconss .emergency1 {
    display: none;
  }

  .iconss .emergency2 {
    display: none;
  }
  .iconss .emergency {
    display: none;
  }

  .wrapper1 .logo {
    display: none;
  }

  .wrapper1 .mobilelogo {
    display: block;
    width: 25%;
    margin-left: -25px;
  }

  nav .wrapper1 {
    background-color: #ff7800;
  }

  .wrapper1 .mobileLinks {
    display: inline;
    margin-top: -12%;
    margin-left: 10px;
    margin-bottom: 5px;

  }

  .wrapper1 .mobileLinks1 {
    margin-left: -26%;
    margin-top: 10%;
    display: block;
  }

  #Topmargin {
    margin-top: 140px;
  }

  #Topmargin1 {
    margin-top: 108px;
  }

  .wrapper1 .mobileLinks1 .em2 {
    background-color: white;
    color: rgb(0, 0, 96);
    text-decoration: none;
    padding: 5%;
    border-radius: 5px;
    font-size: 14px;
  }

  .wrapper1 .mobileLinks .em1 {
    background-color: white;
    color: red;
    text-decoration: none;
    padding: 5%;
    border-radius: 5px;
    font-size: 16px;

  }

  .wrapper1 .mobileLinks .em2 {
    background-color: white;
    color: red;
    text-decoration: none;
    padding: 10%;
    padding-bottom: -25%;
  }

  .wrapper1 .mobileInsurance {
    display: block;
    margin-top: 12%;
    
  }

  .wrapper1 .mobileInsurance>img {
    width: 15%;
    margin-left: 12.5%;
    margin-top: -15%;
    
    position: fixed;
  }

  .wrapper1 .insurance {
    display: block;
    margin-left: 29px;
   text-align: center;
    font-size: 10px;
    
    line-height: 125%;
    text-decoration: none;
    color: #fff;

  }

  .wrapper1 #menu-btn {
    background-color: #242526;
  }

 
}

@media (max-width:399px) {
  .iconss .emergency1 {
    display: none;
  }

  .iconss .emergency2 {
    display: none;
  }

  .wrapper1 .logo {
    display: none;
  }

  .wrapper1 .mobilelogo {
    display: block;
    width: 25%;
    margin-left: -25px;
  }

  nav .wrapper1 {
    background-color: #ff7800;
  }

  .wrapper1 .mobileLinks {
    display: inline;
    margin-top: -12%;
    margin-left: 10px;
    margin-bottom: 5px;

  }

  .wrapper1 .mobileLinks1 {
    margin-left: -29.5%;
    margin-top: 10%;
    display: block;
  }

  #Topmargin {
    margin-top: 140px;
  }

  #Topmargin1 {
    margin-top: 108px;
  }

  .wrapper1 .mobileLinks1 .em2 {
    background-color: white;
    color: rgb(0, 0, 96);
    text-decoration: none;
    padding: 5%;
    border-radius: 5px;
    font-size: 14px;
  }

  .wrapper1 .mobileLinks .em1 {
    background-color: white;
    color: red;
    text-decoration: none;
    padding: 5%;
    border-radius: 5px;
    font-size: 16px;

  }

  .wrapper1 .mobileLinks .em2 {
    background-color: white;
    color: red;
    text-decoration: none;
    padding: 10%;
    padding-bottom: -25%;
  }

  .wrapper1 .mobileInsurance {
    display: block;
  }

  .wrapper1 .mobileInsurance>img {
    width: 15%;
    margin-left: 43px;
    margin-top: -12%;
    position: fixed;
  }

  .wrapper1 .insurance {
    display: block;
    margin-left: 29px;
    margin-top: 13%;
    line-height: 125%;
    font-size: 10px;
    text-decoration: none;
    color: #fff;

  }

  .wrapper1 #menu-btn {
    background-color: #242526;
  }
}

@media (max-width:360px) {
  .iconss .emergency {
    display: none;
  }

  .wrapper1 .logo {
    display: none;
  }

  .wrapper1 .mobilelogo {
    display: block;
    width: 25%;
    margin-left: -25px;
  }

  nav .wrapper1 {
    background-color: #ff7800;
  }

  .wrapper1 .mobileLinks {
    display: inline;
    margin-top: -12%;
    margin-left: 10px;
    margin-bottom: 5px;

  }

  .wrapper1 .mobileLinks1 {
    margin-left: -31%;
    margin-top: 10%;
    display: block;
  }

  #Topmargin {
    margin-top: 140px;
  }

  #Topmargin1 {
    margin-top: 108px;
  }

  .wrapper1 .mobileLinks1 .em2 {
    background-color: white;
    color: rgb(0, 0, 96);
    text-decoration: none;
    padding: 5%;
    border-radius: 5px;
    font-size: 14px;
  }

  .wrapper1 .mobileLinks .em1 {
    background-color: white;
    color: red;
    text-decoration: none;
    padding: 5%;
    border-radius: 5px;
    font-size: 16px;

  }

  .wrapper1 .mobileLinks .em2 {
    background-color: white;
    color: red;
    text-decoration: none;
    padding: 10%;
    padding-bottom: -25%;
  }

  .wrapper1 .mobileInsurance {
    display: block;
  }

  .wrapper1 .mobileInsurance>img {
    width: 15%;
    margin-left: 10%;
    margin-top: -12%;
    position: fixed;
  }

  .wrapper1 .insurance {
    display: block;
    margin-left: 29px;
    margin-top: 13%;
    text-decoration: none;
    color: #fff;
    
    font-size: 8px;
    line-height: 125%;

  }

  .wrapper1 #menu-btn {
    background-color: #242526;
  }
}


.iconss .dropdown-content a {
  color: skyblue;
  min-width: 300px;

  margin-left: -30px;
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
}

.iconss .dropdown-content a:hover {

  color: black;

}

.iconss .dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width:500px) {
  .iconss .dropdown-content a {
    margin-left: -25px;
    min-width: 230px;

  }

}


@media screen and (max-width:500px) {
  .iconss a {

    margin: 10px;

  }

}




nav {

  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;


}

nav .wrapper {
  position: relative;


  max-width: 1300px;
  padding: 0px 30px;
  height: 100px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .logo a {
  color: black;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}

.wrapper .nav-links {
  display: inline-flex;
}

nav .wrapper1 {
  position: relative;


  max-width: 1300px;
  padding: 0px 30px;
  height: 100px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper1 .logo a {
  color: black;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}

.wrapper1 .nav-links {
  display: inline-flex;
}

.nav-links li {
  list-style: none;
}

.nav-links li a {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.nav-links li a:hover {
  background: #ff7800;
}

.nav-links .mobile-item {
  display: none;
}

.nav-links .drop-menu {
  position: absolute;
  background: #ff7800;
  width: 380px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.nav-links .drop-menued {
  position: absolute;
  background: #ff7800;
  width: 380px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.nav-links .drop-menus {
  position: absolute;
  background: #ff7800;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.nav-links .drop-menulink {
  position: absolute;
  background: #ff7800;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 100px;
  opacity: 1;
  visibility: visible;
}

.nav-links li:hover .drop-menus,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 100px;
  opacity: 1;
  visibility: visible;
}

.nav-links li:hover .drop-menulink,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 100px;
  opacity: 1;
  visibility: visible;
}

.nav-links li:hover .drop-menued,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 100px;
  opacity: 1;
  visibility: visible;
}

.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}

.drop-menus li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}

.drop-menulink li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}

.drop-menued li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}

.mega-box .content {
  background: #ff7800;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 45px;
}

.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content .row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}

.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.row .mega-links li {
  padding: 0 20px;
}

.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
  display: block;
  width: 250px;
}

.row .mega-links li a:hover {
  color: #f2f2f2;
}

.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

.wrapper1 .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.wrapper1 .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

@media screen and (max-width: 970px) {
  .wrapper .btn {
    display: block;
  }

  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #ff7800;
    display: block;
    padding: 50px 10px;
    line-height: 30px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }

  .wrapper1 .btn {
    display: block;
  }

  .wrapper1 .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #ff7800;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }

  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #242526;
  }

  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }

  #menu-btn:checked~.nav-links {
    left: 0%;
  }



  #menu-btn:checked~.btn.menu-btn {
    display: none;
  }

  #close-btn:checked~.btn.menu-btn {
    display: block;
  }

  .nav-links li {
    margin: 15px 10px;
  }

  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }

  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }

  .nav-links .drop-menus {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }

  /* .......... */
  .nav-links .drop-menued {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 100px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }

  .nav-links .drop-menulink {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 30px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }

  #showDrop:checked~.drop-menu,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }

  #showDroped:checked~.drop-menued,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }


  #showDrops:checked~.drop-menus,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }

  #showDropelink:checked~.drop-menus,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }

  #showDropelink:checked~.drop-menulink,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }

  /* doubt... */
  #showDrop:checked~.drop-menu,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }

  #showDroped:checked~.drop-menued,
  #showMega:checked~.mega-box {
    max-height: 100%;
  }

  .nav-links .desktop-item {
    display: none;
  }

  .nav-links .desktop-itemed {
    display: none;
  }

  .nav-links .desktop-items {
    display: none;
  }

  .nav-links .mobile-item {
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .nav-links .mobile-item:hover {
    background: whitesmoke;
  }

  .drop-menu li {
    margin: 0;
  }

  .drop-menu li a {
    border-radius: 5px;
    font-size: 18px;
  }

  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }

  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }

  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }

  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }

  .row .mega-links li {
    margin: 0;
  }

  .content .row header {
    font-size: 19px;
  }
}

nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}

.body-text div {
  font-size: 45px;
  font-weight: 600;
}



/* Testmonials.....styles */


h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;

}

.section {
  margin-left: 20px;
  max-width: 1200px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;

  }
}


.icon {
  font-size: 3rem;
  color: hsl(21, 62%, 45%);
}

.section-center {
  margin: 0 auto;

  width: 80vw;
  height: 250px;
  max-width: 620px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

article.lastSlide {
  transform: translateX(-100%);
}

article.nextSlide {
  transform: translateX(100%);
}

.person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 90px;
  height: 100px;
  object-fit: cover;
  border: 4px solid hsl(210, 31%, 80%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

article h4 {
  text-transform: uppercase;
  color: hsl(21, 62%, 45%);

}

.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: black;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}

.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.text {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 0.5rem;
  line-height: 2;
  color: black;
}

.prev,
.next {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  background: hsl(210, 22%, 49%);
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
}

.prev:hover,
.next:hover {
  background: hsl(21, 62%, 45%);
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

@media (min-width: 800px) {
  .text {
    max-width: 100em;
  }

  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}

@media screen and (max-width:500px) {
  .section-center {
    height: 320px;
    max-width: 650px;
  }

}



/* banner styles.... */
.strips li {
  display: inline-block;
  padding: 15px 15px;
  margin: 15px;
  text-decoration: none;
}

.strips li a {
  text-decoration: none;
  color: white;
  transition: 0.3s;
}

.strips li a:hover {

  color: black;
}

@media screen and (max-width:450px) {
  .strips ul {


    padding-left: 0px;
    margin-left: -5px;

  }

}

/* 
docter image adjustments and effects.... */

@media screen and (max-width:500px) {
  .services .servicesimg {


    margin-top: -50px;
    padding-top: 0px;
    margin-bottom: 20px;
  }

}

.services .servicesimg {
  transition: all 0.3s ease;
}

.services .servicesimg:hover {
  transform: scale(1.08);
}

/* whatspp styles....... */

.whatsapp_float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 7px;
  left: 5px;
  background-color: rgb(83, 68, 40);


  color: #fff;
  border-radius: 80px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  transition: 0.3s;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
/* @media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 12px;
    right: 10px;
    font-size: 22px;
  }
} */

.whatsapp_float:hover {
  color: whitesmoke;
  color: rgb(52, 186, 240);
  background-color: rgb(245, 41, 41);
}


/* Insurance_float....  */

.insurance_float {
  text-decoration: none;
  position: fixed;
  left: 30px;
  width: 150px;
  color: #fff;
  bottom: 13px;
  background-color: rgb(245, 41, 41);
  padding: 5px 12px;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  animation: blinking3 1s infinite;
  border-radius: 50px 50px 50px 50px;
  z-index: 100;
  transition: all 0.3s ease;
}

.insurance_float:hover {
  color: black;
  background-color: rgb(245, 41, 41);
}




/* MAPPING ADJUSTMENTS...... */
.map iframe {
  width: 97%;
  height: 150px;
  border: 3px solid #fff;
  box-shadow: 0 0 20px #ccc;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 15px;
}

@media screen and (max-width:500px) {
  .map iframe {
    width: 95%;
  }

}

@media screen and (max-width:500px) {
  .PARASET {
    font-size: small;
  }

}

/* youtube content styles */

@media (max-width:500px) {

  .container .YoutubeContent {
    margin-top: -55px;

  }
}

/* TESTMONIALS STYLES */

@media screen and (min-width:501px) {

  .card-wrapper {
    display: flex;



  }

  .carded {
    margin: 2em;
    width: calc(100%/3);
    height: 600px;
    width: 300px;


  }

  .image-wrapper {
    height: 22vw;
    margin: 0 auto;
  }
}

.card-wrapper .carded {
  border: 2px solid #000;
  box-shadow: #ff7800 0px 5px 15px -12px inset, rgba(244, 35, 35, 0.8) 0px 5px 36px -18px inset;
}

.card-wrapper .carded .image {
  background-color: #ff7800;
}

@media screen and (max-width:500px) {
  .carded:not(:first-child) {
    display: none;
    width: 100%;
  }
}

/* zoom effect */
/* .homeclass .homeclass1>img{
  animation: zoom 15s;
}
@keyframes zoom{
  from{
    transform: scale(1,1);
  }
  to{
    transform: scale(1.3,1.3);
  }
} */




.strips h1 {
  margin-bottom: auto;
  margin-right: auto;

}

.strips li a {
  margin-bottom: auto;
  margin-right: auto;

}

@media (max-width:500px) {
  .strips li a {
    margin-left: 20px;
  }
}

@media (max-width:500px) {
  .EventsCards .row {
    margin-left: auto;
    margin-right: auto;

  }
}

.text-center .DartLink a {
  color: yellow;
  transition: all 1s ease;
}

.text-center .DartLink a:hover {
  color: white;

}

.metaTags {
  background-color: #ff7800;
  align-items: flex-end;
  justify-items: left;
  position: fixed;
  float: right;

}

.metaTags .facebook {
  position: relative;
}


@media (max-width:500px) {
  .sbuttons {
    bottom: 22%;
    position: fixed;
    margin-right: -2%;
    right: 0;
    float: right;
    display: block;
  }
  
  .facebook {
    display: block;
    background-color: #316FF6;
    color: white;
    
    width: 100%;
    position: relative;
    border-radius: 33px 0 0 33px;
    padding: 10px 20px;
    margin-bottom: -20%;
  
  }
  
  .facebook>i {
    font-size: 23px;
    line-height: 20px;
    transition: all .2s ease-in-out;
    transition-delay: 2s;
    padding: 2%;
  }
  
  .linkedin {
    display: block;
    background-color: #0077b5;
    color: white;
    
    width: 100%;
    position: relative;
    border-radius: 33px 0 0 33px;
    padding: 10px 20px;
    margin-bottom: -20%;
  
  }
  
  .linkedin>i {
    font-size: 23px;
    line-height: 20px;
    transition: all .2s ease-in-out;
    transition-delay: 2s;
    padding: 2%;
  }
  
  .youtube {
    display: block;
    background-color: red;
    color: white;
  
    width: 100%;
    position: relative;
    border-radius: 33px 0 0 33px;
    padding: 10px 20px;
    margin-bottom: -20%;
  
  }
  
  .youtube>i {
    font-size: 23px;
    line-height: 20px;
    transition: all .2s ease-in-out;
    transition-delay: 2s;
    padding-top: 55%;
  }
  
  .instagram {
    display: block;
    background-color: #cd486b;
    color: white;
    margin: 1px 1px;
    width: 100%;
    position: relative;
    border-radius: 33px 0 0 33px;
    padding: 10px 20px;
   
  
  }
  
  .instagram>i {
    font-size: 23px;
    line-height: 20px;
    transition: all .2s ease-in-out;
    transition-delay: 2s;
    padding: 2%;
  }
  
}

.sbuttons {
  bottom: 22%;
  position: fixed;
 
  right: 0;
  float: right;
  display: block;
}

.facebook {
  display: block;
  background-color: #316FF6;
  color: white;
  
  width: 100%;
  position: relative;
  border-radius: 33px 0 0 33px;
  padding: 10px 20px;
  margin-bottom: -20%;

}

.facebook>i {
  font-size: 23px;
  line-height: 20px;
  transition: all .2s ease-in-out;
  transition-delay: 2s;
  padding: 2%;
}

.linkedin {
  display: block;
  background-color: #0077b5;
  color: white;
  
  width: 100%;
  position: relative;
  border-radius: 33px 0 0 33px;
  padding: 10px 20px;
  margin-bottom: -20%;

}

.linkedin>i {
  font-size: 23px;
  line-height: 20px;
  transition: all .2s ease-in-out;
  transition-delay: 2s;
  padding: 2%;
}

.youtube {
  display: block;
  background-color: red;
  color: white;

  width: 100%;
  position: relative;
  border-radius: 33px 0 0 33px;
  padding: 10px 20px;
  margin-bottom: -20%;

}

.youtube>i {
  font-size: 23px;
  line-height: 20px;
  transition: all .2s ease-in-out;
  transition-delay: 2s;
  padding-top: 55%;
}

.instagram {
  display: block;
  background-color: #cd486b;
  color: white;
  margin: 1px 1px;
  width: 100%;
  position: relative;
  border-radius: 33px 0 0 33px;
  padding: 10px 20px;
 

}

.instagram>i {
  font-size: 23px;
  line-height: 20px;
  transition: all .2s ease-in-out;
  transition-delay: 2s;
  padding: 2%;
}

.insuranceLogo{
 
  width: 5%;
  margin-top: -1%;
}

.FridayIdMoreInformation>button{
  background-color: white;
  color: rgb(245, 41, 41);
  padding: 1%;
  border-radius: 15px;
  transition: all 1s ease-in-out;
  width: 70%;
}
.FridayIdMoreInformation>button:hover{
  color: #ff7800;
}

@media (max-width:500px) {
  .AddressWeb{
    display: none;
  }
}

.AddressMobile{
  display: none;
}

@media (max-width:500px) {
  .AddressMobile{
    display: block;
    font-size: 15.5px;
  }
}

/* home news image styles */
.newsImage{
    border: 1.5px solid black;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 15px;
    border-radius: 5px;
}
@media (max-width:499px) {
  .newsCard{
  
    margin-left: -10%;
  }
}
